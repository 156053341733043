import { EventEmitter, Inject, Injectable } from "@angular/core";
import { IPanelCommand, IPanelService, PanelWidthEnum } from "a3dn-shared-lib";
import { Observable, Subject } from "rxjs";
import { ISideNavService } from "./I-side-nav";
import { COMMAND_MAPPER } from "./command-mapper";

@Injectable({
	providedIn: "root",
})
export class PanelService implements IPanelService {
	commands: IPanelCommand<any, any>[] = [];
	constructor(@Inject("ISideNavService") private ISideNavService: ISideNavService) {}

	enqueue<T, R>(command: IPanelCommand<T, R>) {
		this.commands.push(command);
		return this;
	}

	run(panelWidth: PanelWidthEnum): Observable<EventEmitter<any>[]> {
		let result: Subject<EventEmitter<any>[]> = new Subject<EventEmitter<any>[]>();

		this.commands.forEach((command) => {
			command.component = COMMAND_MAPPER[command.type];
			let events: EventEmitter<any>[] = [];
			events.push(command.commandResult);
			command.commandResult.subscribe((res) => {
				result.next(res);
			});
		});
		this.ISideNavService.inject(this.commands);
		this.ISideNavService.openPanel(panelWidth);

		return result.asObservable();
	}

	closePanel() {
		this.commands = [];
		this.ISideNavService.closePanel();
	}
}
