import { NewSettlementComponent } from "a3dn-settlements-lib";
import { IPanelCommandType } from "a3dn-shared-lib";
import { NewSmsPanelComponent } from "a3dn-sms-lib";
import { TicketNewMessagePanelComponent } from "ticket-domain-lib";

export const COMMAND_MAPPER: { [key in IPanelCommandType]: any } = {
	Message: TicketNewMessagePanelComponent,
	Sms: NewSmsPanelComponent,
	NewSettlement: NewSettlementComponent,
};
